form#login .ant-row.ant-form-item {
    margin: 8px 0 0 0;
}

    form#login h1 {
        margin: 0 0 0 0;
        font-weight: 600;
        font-size: 28px;
        color: #6246EA;
    }

    form#login h2 {
        margin: 0 0 16px 0;
        text-align: center;
        font-weight: 200;
        font-size: 48px;
        color: #6C6D72;
    }