.chatbot-sectionHeader {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px 10px #EBECFF;
    border: 1px solid #EBECFF;
    border-radius: 10px;

    .btn_seeAllChatbots {
        flex: 0 0 240px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #40456B !important;
    }

    .info-W {
        flex-grow: 4;
        
        .status {
            display: inline-block;
            border-radius: 2px;
            padding: 2px 10px 2px 10px;
            text-align: center;
            font-size: 14px;
            line-height: 18px;
        }

        .status.active {
            background: #31D696;
            color: #FFFFFF;
        }
    
        .status.inactive {
            background: #EBEBEB;
            color: rgba(0, 0, 0, 0.38);
        }

        .name {
            font-weight: 700;
            font-size: 28px;
            line-height: 40px;
            color: #40456B;
        }
    }

    .actions-W {
        align-self: flex-end;

        img.chatbotIMG {
            object-fit: cover;
            width: 140px;
            height: 90px;
        }

        .chatbot_btnPublish {
            background: #6800D0;;
            border: 1px solid #6800D0;
            border-radius: 50px;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF !important;
        }

        .chatbot_btnUnpublish {
            background: #FF5956;
            border: 1px solid #FF5956;
            border-radius: 50px;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF !important;
        }

        .chatbot_btnPreview {
            background: rgba(104, 0, 208, 0.05);
            border: 1px solid #6800D0;
            border-radius: 50px;
            font-weight: 500;
            font-size: 14px;
            color: #6800D0 !important;
        }

        .chatbot_btnEdit {
            background: #F8F8F8;
            border: 1px solid #40456B;
            border-radius: 50px;
            font-weight: 500;
            font-size: 14px;
            color: #40456B !important;
        }
    }
}

.chatbot-sectionBody {
    .chatbot-flowDiagram {
        background: #FFFFFF;
        box-shadow: 0px 10px 20px 10px #EBECFF;
        border: 1px solid #EBECFF;
        border-radius: 5px;

        .react-flow__node {
            background: linear-gradient(180deg, #FFFFFF 0%, #F9F9FF 100%);
            border: 1px solid #EBECFF;
            box-sizing: border-box;
            box-shadow: 0px 4px 4px #ebecff;
            border-radius: 5px;
            width: 240px;
            text-align: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #40456B;
        }
    }

    .chatbot-actions {
        background: #FFFFFF;
        box-shadow: 0px 10px 20px 10px #EBECFF;
        border: 1px solid #EBECFF;
        border-radius: 5px;
        
        .header {
            font-weight: 700;
            font-size: 20px;
            line-height: 32px;
            color: #6800D0;
        }
    
        .action-C {
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(180deg, #FFFFFF 0%, #F9F9FF 100%);
            border: 1px solid #EBECFF;
            box-sizing: border-box;
            box-shadow: 0px 4px 4px #EBECFF;
            border-radius: 5px;
            padding: 16px 0 16px 0;
            text-align: center;
            font-size: 14px;
            line-height: 18px;
            height: 96px;
            color: #767990;
    
            .blockActions_icon.button {
                display: inline-block;
                background: #FFFFFF;
                border: 1px solid #40456B;
                box-sizing: border-box;
                box-shadow: 2px 2px 0px #40456B;
                border-radius: 5px;
                padding: 0 12px 0 12px;
                font-weight: 500;
                font-size: 9px;
                line-height: 24px;
                color: #40456B;
            }
        }

        .action-C:hover {
            cursor: pointer;
        }
    }
}

.chatbot-blocks-form-W {
    position: relative;
    z-index: 999;

    .icon {
        display: inline-block;
        margin: 0 4px 0 8px;
        font-size: 20px;
        color: #767990 !important;
    }

    .icon:hover {
        cursor: pointer;
    }

    .picker {
        z-index: 888;
        position: absolute;
        bottom: 68px;
        left: 0px;
    }
}

.chatbot-blocks-textArea {
    position: relative;
    background: #FFFFFF !important;
    border: 1px solid #EBECFF !important;
    border-radius: 10px !important;
}

.dragger_addCarouselNode {
    .ant-upload.ant-upload-btn {
        padding: 0 0 0 0 !important;
    }
}

.dragger_editCarouselNode {
    .ant-upload.ant-upload-btn {
        padding: 0 0 0 0 !important;
    }
}

.addNodeModal_addCarouselBlock_select {
    .ant-select-selector {
        background: #EBECFF !important;
        border: 1px solid #F0F0F0 !important;
        border-radius: 0 !important;
    }
}
@primary-color: #6800D0;@error-color: #DC143C;@text-color: #40456B;