.ant-layout-header .ant-switch-checked {
    background: #FFD967;
}

.notificationsMenu .ant-badge-count {
    border-radius: 100px;
    right: 6px !important;
    min-width: 18px;
    height: 18px;
    font-size: 11px;
    line-height: 18px;
}

    .ant-dropdown-menu {
        border-radius: 5px !important;
        padding: 0 0 0 0 !important;
    }

        .ant-dropdown-menu-item.status {
            font-size: 15px;
            font-weight: 700;
        }

        .ant-dropdown-menu-item.status.online {
            color: #31D696 !important;
        }

        .ant-dropdown-menu-item.status.online.active {
            border-radius: 5px 0px 0px 0px;
            border-left: 5px solid #31D696;
        }

        .ant-dropdown-menu-item.status.away {
            color: #FFDF6C !important;
        }

        .ant-dropdown-menu-item.status.away.active {
            border-radius: 5px 0px 0px 0px;
            border-left: 5px solid#FFDF6C;
        }

        .ant-dropdown-menu-item.status.offline {
            color: #FF5956 !important;
        }

        .ant-dropdown-menu-item.status.offline.active {
            border-radius: 5px 0px 0px 0px;
            border-left: 5px solid#FF5956;
        }

            .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
                padding: 6px 12px 6px 12px !important;
                white-space: normal !important;
            }