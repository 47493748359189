.errorLayout {
    background: rgba(98, 70, 234, 0.05);
    padding: 64px 96px;
    min-height: 100vh;
    
}

    .errorLayout .status {
        font-weight: 400;
        font-size: 64px;
    }

    .errorLayout .message {
        margin: 0 0 0 24px;
        font-weight: 200;
        font-size: 64px;
    }