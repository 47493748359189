aside.ant-layout-sider {
    flex: 0 0 240px !important;
    max-width: 240px !important;
    min-width: 240px !important;
    width: 240px !important;
}

    aside.ant-layout-sider ul.ant-menu.ant-menu-inline {
        background: transparent !important;
        border-right: 1px solid #6246EA !important;
        margin: 0 0 0 0 !important;
        padding: 0 0 0 0 !important;
    }

        aside.ant-layout-sider ul.ant-menu.ant-menu-inline li.ant-menu-item {
            background: transparent !important;
            border-right: 1px solid #6246EA;
            margin: 0 0 0 0 !important;
            padding: 0 0 0 0 !important;
        }

        aside.ant-layout-sider ul.ant-menu.ant-menu-inline li.ant-menu-item.ant-menu-item-selected {
            background: transparent !important;
        }

        aside.ant-layout-sider ul.ant-menu.ant-menu-inline li.ant-menu-item.ant-menu-item-selected::after {
            display: none;
        }

            aside.ant-layout-sider ul.ant-menu.ant-menu-inline li.ant-menu-item a {
                font-weight: 700;
                color: #FFFFFF !important;
            }

            aside.ant-layout-sider ul.ant-menu.ant-menu-inline li.ant-menu-item.ant-menu-item-selected a {
                color: #FFD967 !important;
            }

        aside.ant-layout-sider ul.ant-menu.ant-menu-inline li.ant-menu-submenu {
            background: transparent !important;
            border-right: 1px solid #6246EA !important;
            margin: 0 0 0 0 !important;
            padding: 0 0 0 0 !important;
            color: #FFFFFF;
        }

            aside.ant-layout-sider ul.ant-menu.ant-menu-inline li.ant-menu-submenu .ant-menu-submenu-title {
                background: transparent !important;
                margin: 0 0 0 0 !important;
                padding-left: 18px !important;
                font-weight: 700;
                color: #FFFFFF !important;
            }

            aside.ant-layout-sider ul.ant-menu.ant-menu-inline li.ant-menu-submenu ul.ant-menu.ant-menu-sub {
                width: unset !important;
                border-left: 1px solid #EBECFF;
                margin-left: 20px !important;
            }

            aside.ant-layout-sider ul.ant-menu.ant-menu-inline li.ant-menu-submenu ul.ant-menu.ant-menu-sub li.ant-menu-item {
                padding-left: 22px !important;
            }

.ant-menu-inline .ant-menu-submenu-title {
    width: unset !important;
}