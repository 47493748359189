.onlineIndicator {
    display: inline-block;
    background: #31D696;
    border: 5px solid #31D696;
    border: 5px solid #31D69633;
    border-radius: 100%;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    width: 18px;
    height: 18px;
    margin: 0 0 0 8px;
}

.outerAwayIndicator {
    display: inline-block;
    background: #FFD967;
    border: 5px solid #FFD967;
    border: 5px solid #FFD96733;
    border-radius: 100%;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    width: 18px;
    height: 18px;
    margin: 0 0 0 8px;
}

.outerOfflineIndicator {
    display: inline-block;
    background: #FF5956;
    border: 5px solid #FF5956;
    border: 5px solid #FF595633;
    border-radius: 100%;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    width: 18px;
    height: 18px;
    margin: 0 0 0 8px;
}