.rdw-editor-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.rdw-editor-wrapper .rdw-editor-toolbar {
    border: 0 none;
    border-bottom: 1px solid #F0F0F0;
}

.public-DraftEditor-content {
    min-height: 120px;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
    margin: 8px 8px 8px 8px !important;
    padding: 0 0 0 0 !important;
}

.DraftEditor-root {
    max-height: 280px;
}