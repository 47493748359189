.header {
    z-index: 1;
    background: linear-gradient(to right, #6800D0, #410083) !important;
    width: 100%;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.25);
    padding: 0 0 0 0 !important;
    font-weight: 500;
}

    .header .actions {
        float: right;
        margin: 0 32px 0 0;
    }

        .header .actions a {
            color: #FFFFFF !important;
        }

        .header .actions a:hover {
            color: #6246EA;
        }

    
    .flexRow {
        display: flex;
        width: 100%;
    }
    
        .flexRow .colContentPaneLeft {
            width: 40px;
            flex: 0 0 40px;
            -ms-flex: 0 0 40px;
        }
    
        .flexRow .colContentPaneRight {
            overflow-y: hidden;
            max-width: 100%;
            flex-grow: 2;
        }

            .notificationC {
                border-bottom: 1px solid #EBECFF;
                padding: 6px 8px;
            }

            .notificationC:hover {
                background: #EBECFF;
                cursor: pointer;
            }

                .notificationC .notificationBody {
                }

                    .notificationC .notifiedBy {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 15px;
                    }

                        .notificationC .notificationBody .notifiedBy .seenIndicator {
                            margin: 0 0 0 4px;
                            font-size: 18px;
                            color: #6246EA;
                        }

                    .notificationC .message {
                        width: 100%;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                    }

                    .notificationC .dateAndTime {
                        font-size: 10px;
                        line-height: 15px;
                    }

            .seeAllBtn {
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                color: #40456B;
            }