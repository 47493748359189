.flexRowLG {
    display: flex;
    width: 100%;
}

    .flexRowLG .colContentPaneLeft {
        border-radius: 5px;
        width: 440px;
        flex: 0 0 440px;
        -ms-flex: 0 0 440px;
        margin: 0 0 0 6px;
    }

        .flexRowLG .colContentPaneLeft .headerC {
            background: #FDFCFF;
            border-bottom: 1px solid #EBECFF;
        }

            .flexRowLG .colContentPaneLeft .headerC h2 {
                font-size: 18px;
                line-height: 32px;
            }

        .flexRowLG .colContentPaneLeft .assignedToC {
            background: #FFFFFF;
            border-bottom: 1px solid #EBECFF;
        }

            .flexRowLG .colContentPaneLeft .assignedToC .label {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #40456B;
            }

            .flexRowLG .colContentPaneLeft .assignedToC .helper {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #40456B;
            }

        .flexRowLG .colContentPaneLeft .summary {
            background: #FFFFFF;
            border-bottom: 1px solid #EBECFF;
            font-size: 12px;
        }

            .flexRowLG .colContentPaneLeft .summary .label {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #40456B;
            }

            .flexRowLG .colContentPaneLeft .summary .subtitle {
                font-weight: 500 !important;
            }

            .flexRowLG .colContentPaneLeft .summary .info {
                text-align: right;
            }

        .flexRowLG .colContentPaneLeft .reference {
            background: #FFFFFF;
            border-bottom: 1px solid #EBECFF;
            font-size: 12px;
        }

            .flexRowLG .colContentPaneLeft .reference .label {
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #40456B;
            }

            .flexRowLG .colContentPaneLeft .reference .subtitle {
                font-weight: 500 !important;
            }

            .flexRowLG .colContentPaneLeft .reference .info {
                text-align: right;
            }

    .flexRowLG .colContentPaneRight {
        overflow-y: hidden;
        max-width: 100%;
        min-height: 480px;
        flex-grow: 2;
        margin: 0 6px 0 0;
    }

        .flexRowLG .colContentPaneRight .headerC {
            background: #FDFCFF;
            border-bottom: 1px solid #EBECFF;
        }

            .flexRowLG .colContentPaneRight .headerC h1 {
                margin: 0 0 0 0;
                font-size: 18px;
                line-height: 32px;
            }

            .flexRowLG .colContentPaneRight .headerC .changeStatusLabel {
                font-size: 12px;
                color: #767990;
            }

        .flexRowLG .colContentPaneRight .bodyC {
            background: #FFFFFF;
            -webkit-box-shadow: 0px -2px 5px 0px #EBECFF;
            -moz-box-shadow: 0px -2px 5px 0px #EBECFF;
            box-shadow: 0px -2px 5px 0px #EBECFF;
            overflow: scroll;
            max-height: 480px;
        }
            
            .flexRowLG .colContentPaneRight .bodyC .ticketDescriptionC {
                background: #F9FAFF;
                border: 1px solid #EBECFF;
                border-radius: 5px;
                max-width: calc(100% - 160px);
            }

                .flexRowLG .colContentPaneRight .bodyC .ticketDescriptionC .fromC {
                    border-bottom: 1px solid #EBECFF;
                    font-size: 12px;
                }
                
                    .flexRowLG .colContentPaneRight .bodyC .ticketDescriptionC .fromC .fullName {
                        margin: 0 8px 0 0;
                        font-weight: 700;
                        font-size: 14px;
                        color: #6800D0;
                    }

                    .flexRowLG .colContentPaneRight .bodyC .ticketDescriptionC .fromC .email {
                        font-weight: 500;
                        font-size: 12px;
                    }

                    .flexRowLG .colContentPaneRight .bodyC .ticketDescriptionC .dateTime {
                        float: right;
                        font-size: 12px;
                    }

            .flexRowLG .colContentPaneRight .bodyC .commentC {
                background: rgba(104, 0, 208, 0.02);
                border: 1px solid #EBECFF;
                border-radius: 5px;
                max-width: calc(100% - 160px);
                margin: 16px 0 0 auto;
            }

                .flexRowLG .colContentPaneRight .bodyC .commentC .fromC {
                    border-bottom: 1px solid #EBECFF;
                    font-size: 12px;
                }
                
                    .flexRowLG .colContentPaneRight .bodyC .commentC .fromC .displayName {
                        margin: 0 8px 0 0;
                        font-weight: 700;
                        font-size: 14px;
                        color: #6800D0;
                    }

                    .flexRowLG .colContentPaneRight .bodyC .commentC .fromC .email {
                        font-weight: 500;
                        font-size: 12px;
                    }

                    .flexRowLG .colContentPaneRight .bodyC .commentC .dateTime {
                        float: right;
                        font-size: 12px;
                    }

        .flexRowLG .colContentPaneRight .textFieldC {
            background: #FDFCFF;
            border: 1px solid #EBECFF;
            border-radius: 0px 0px 5px 5px;
            padding-bottom: 0 !important;
        }
        

