@import '~antd/dist/antd.less';

body {
    background: #F9FAFF;
}

.container {
    display: block;
    width: 100%;
    max-width: auto;
    align-self: center;
    margin: auto;
}

@media (min-width: 1920px) {
    .container {
        max-width: 1840px;
    }
}

@media (min-width: 1366px) and (max-width: 1919px) {
    .container {
        max-width: 1280px;
    }
}

@media (min-width: 992px) and (max-width: 1365px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .container {
        max-width: 720px;
    }
}

.ant-avatar.ant-avatar-circle {
    background: #FFD967;
    font-weight: 700;
    font-size: 12px;
    color: #000000;
}

.pageBody {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px 10px #EBECFF;
    border-radius: 5px;
    margin: 12px 0 0 0;
    padding: 20px 20px 20px 20px;
}

.spin-wrapper {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
}

h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    color: #40456B;
}

h2 {
    font-weight: 700;
    font-size: 21px;
    line-height: 40px;
    color: #40456B;
}

h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 40px;
    color: #40456B;
}

h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 40px;
    color: #40456B;
}

/* <Tabs> */
.ant-tabs-tab-btn {
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 24px;
}

.ant-tabs-bottom>.ant-tabs-nav:before, .ant-tabs-bottom>div>.ant-tabs-nav:before, .ant-tabs-top>.ant-tabs-nav:before, .ant-tabs-top>div>.ant-tabs-nav:before {
    border-bottom: 1px solid #EBECFF;
}

.ant-tabs-tab .anticon {
    margin-right: 6px;
}
/* </Tabs> */

/* <Table> */
table {
    background: transparent !important;
}

    table thead th {
        background: #EBECFF !important;
        padding: 8px 12px 8px 12px !important;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.02em;
    }

    table thead th:first-child {
        border-top-left-radius: 5px !important;
    }

    table thead th:last-child {
        border-top-right-radius: 5px !important;
    }

    table tbody tr:nth-child(even) {
        background: #F9FAFF;
    }

        table tbody td {
            border-bottom: 1px solid #EBECFF !important;
            padding: 8px 12px 8px 12px !important;
            font-weight: 400;
            font-size: 12px;
            line-height: 1.5;
            color: #000000;
        }

        table tbody td:first-child {
            border-left: 1px solid #EBECFF !important;
        }

        table tbody td:last-child {
            border-right: 1px solid #EBECFF !important;
        }
/* </Table> */

/* <Form> */
.ant-form-item {
    margin: 0 0 12px 0 !important;
}

    .ant-form-item .ant-form-item-label {
        z-index: 99;
        display: inline-block;
        background: transparent;
        border-radius: 5px;
        margin: 0 0 4px 4px;
        padding: 0 0 0 0 !important;
        text-align: left;
        font-weight: 300;
        line-height: 1;
    }

        .ant-form-item .ant-form-item-label label {
            font-size: 12px;
        }

    .ant-form-item .ant-form-item-control-wrapper {
        border-radius: 5px !important;
        z-index: 0;
        margin: 0 0 0 0;
    }

        .ant-form-item .ant-form-item-control-wrapper input {
            border-radius: 5px !important;
            height: 48px;
            font-weight: 400;
            font-size: 14px;
            line-height: 15px;;
        }

        input.curvedInput {
            border-radius: 100px !important;
            padding: 5px 20px 5px 20px;
        }

        .ant-form-item-control.has-error textarea {
            border: 1px solid #f5222d !important;
        }

    .ant-select .ant-select-selection {
        height: 40px;
    }

    .ant-select .ant-select-selection .ant-select-selection__rendered {
        line-height: 40px;
    }

        .ant-select .ant-select-selection .ant-select-selection-selected-value {
            line-height: 40px;
        }

    .ant-select.filterOptions .ant-select-selector {
        background: rgba(104, 0, 208, 0.05);
        border: 1px solid rgba(104, 0, 208, 0.2);
        box-sizing: border-box;
        border-radius: 5px;
    }

    .filterOptions.searchBar.ant-input-affix-wrapper {
        border-radius: 100px !important;
    }

    .ant-input-prefix {
        color: rgba(0, 0, 0, 0.38);
    }

    .ant-input-number, .ant-input-number-focused {
        width: 100% !important;
    }
/* </Form> */

/* <Buttons> */
.btnActions {
    margin: 0 4px 0 4px;
}

.btnInline {
    display: inline-block !important;
}

.btnDefault {
    background: rgba(104, 0, 208, 0.05) !important;
    border: 1px solid rgba(104, 0, 208, 0.2) !important;
    border-radius: 100px;
    font-weight: 500;
    font-size: 14px;
    color: #6800D0 !important;
}

.btnBack {
    background: rgba(64, 69, 107, 0.05) !important;
    border: 1px solid #40456B !important;
    border-radius: 100px;
    font-weight: 500;
    font-size: 14px;
    color: #40456B !important;
}

.btnAdd {
    background: #6800D0 !important;
    border: 1px solid rgba(104, 0, 208, 0.2) !important;
    box-sizing: border-box;
    border-radius: 100px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF !important;
}

.btnSecondary {
    background: rgba(104, 0, 208, 0.05);   
    border: 1px solid rgba(104, 0, 208, 0.2);
    box-sizing: border-box;
    border-radius: 100px;
    font-weight: 500;
    font-size: 14px;
    color: #6800D0;
}

.btnDelete {
    background: rgba(255, 89, 86, 0.05);
    border: 1px solid rgba(255, 89, 86, 0.2) !important;
    box-sizing: border-box;
    border-radius: 50px;
    font-weight: 500;
    font-size: 14px;
    color: #FF5956 !important;
}

.btnEdit {
    background: rgba(104, 0, 208, 0.05);
    border: 1px solid rgba(104, 0, 208, 0.2);
    border-radius: 100px;
    font-weight: 500;
    font-size: 14px;
    color: #6800D0 !important
}

.btnDisabled {
    background: #f5f5f5 !important;
    border-radius: 100px !important;
    border-color: #d9d9d9 !important;
    color: rgba(0,0,0,.25) !important;
}

.btnTransparent {
    background: transparent !important;
    border: 0 none !important;
    color: #FFFFFF !important;
}

.btnPlain {
    background: transparent !important;
    border: 0 none !important;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    color: #6800D0 !important
}
/* </Buttons> */

/* Modal */
.ant-modal-content {
    background-color: #F9FAFF !important;
}

    .ant-modal-content .ant-modal-body {
        padding: 64px 36px;
    }
/* Modal */

.text-center {
    text-align: center;
}

/* <Margin&Padding> */
.mt-1 { margin-top: 4px }
.mt-2 { margin-top: 8px }
.mt-3 { margin-top: 12px }
.mt-4 { margin-top: 16px }

.mr-1 { margin-right: 4px }
.mr-2 { margin-right: 8px }
.mr-3 { margin-right: 12px }
.mr-4 { margin-right: 16px }

.mb-1 { margin-bottom: 4px }
.mb-2 { margin-bottom: 8px }
.mb-3 { margin-bottom: 12px }
.mb-4 { margin-bottom: 16px }

.ml-1 { margin-left: 4px }
.ml-2 { margin-left: 8px }
.ml-3 { margin-left: 12px }
.ml-4 { margin-left: 16px }

.my-1 { margin-top: 4px; margin-bottom: 4px }
.my-2 { margin-top: 8px; margin-bottom: 8px }
.my-3 { margin-top: 12px; margin-bottom: 12px }
.my-4 { margin-top: 16px; margin-bottom: 16px }

.mx-1 { margin-right: 4px; margin-left: 4px }
.mx-2 { margin-right: 8px; margin-left: 8px }
.mx-3 { margin-right: 12px; margin-left: 12px }
.mx-4 { margin-right: 16px; margin-left: 16px }

.m-0 { margin: 0px !important; }

.pt-1 { padding-top: 4px }
.pt-2 { padding-top: 8px }
.pt-3 { padding-top: 12px }
.pt-4 { padding-top: 16px }

.pr-1 { padding-right: 4px }
.pr-2 { padding-right: 8px }
.pr-3 { padding-right: 12px }
.pr-4 { padding-right: 16px }

.pb-1 { padding-bottom: 4px }
.pb-2 { padding-bottom: 8px }
.pb-3 { padding-bottom: 12px }
.pb-4 { padding-bottom: 16px }

.pl-1 { padding-left: 4px }
.pl-2 { padding-left: 8px }
.pl-3 { padding-left: 12px }
.pl-4 { padding-left: 16px }

.py-1 { padding-top: 4px; padding-bottom: 4px }
.py-2 { padding-top: 8px; padding-bottom: 8px }
.py-3 { padding-top: 12px; padding-bottom: 12px }
.py-4 { padding-top: 16px; padding-bottom: 16px }

.px-1 { padding-right: 4px; padding-left: 4px }
.px-2 { padding-right: 8px; padding-left: 8px }
.px-3 { padding-right: 12px; padding-left: 12px }
.px-4 { padding-right: 16px; padding-left: 16px }

.p-1 { padding: 4px }
.p-2 { padding: 8px }
.p-3 { padding: 12px }
.p-4 { padding: 16px }
/* </Margin&Padding> */

.ant-typography {
    color: inherit !important;
}

.error {
    color: #DC143C;
}

/* <Tag> */
.ant-tag {
    margin: 0 0 0 0 !important;   
}
/* </Tag> */

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.no-error-message .ant-form-item-explain.ant-form-item-explain-connected {
    display: none;
}

.box-shadow-none,
.box-shadow-none textarea,
.box-shadow-none input,
.box-shadow-none .ant-select-selector {
    box-shadow: none !important;
}
@primary-color: #6800D0;@error-color: #DC143C;@text-color: #40456B;