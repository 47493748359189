@keyframes fly-in {
    0% {
        transform: scale(0.85) translateY(10%);
        opacity: 0;
    }
    100% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}

.page-Channels {
    .section-title {
        border-bottom: 1px solid #ebecff;
    }

    section.chat-list {
        display: block;
        background: #ffffff;

        .chat-list-item.active {
            background: rgba(65, 79, 247, 0.05);
            border: 1px solid #6800d0;
        }

        .chat-list-item {
            background: #fdfcff;
            border: 1px solid #ebecff;
            box-sizing: border-box;
            border-radius: 5px;
            margin: 8px 0 0 0;
            padding: 12px 10px 12px 10px;

            .avatar {
                float: left;
                margin-right: 10px;
            }

            .container {
                .row1 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;

                    .username {
                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: auto;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 14px;
                        color: #40456b !important;
                    }

                    .time {
                        font-size: 10px;
                        line-height: 14px;
                        color: #666;
                    }
                }

                .row2 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;

                    .message {
                        flex-grow: 1;
                        flex-shrink: 1;
                        font-size: 11px;
                        font-size: 12px;
                        line-height: 14px;
                        color: #767990 !important;
                    }

                    .badge-container {
                        min-width: 40px;
                        text-align: right;
                    }
                }
            }
        }
    }

    .btn-start-chat {
        outline: none;
        background: transparent;
        border: 0 none;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        color: #6800d0;
    }

    .start-new-message-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 4px;
        right: 4px;

        .btn-back {
            border: 0 none;
            padding: 0 4px 0 4px;
        }

        .operator-list {
            .operator {
                cursor: pointer;
                border-radius: 4px;
                padding: 10px 0 10px 0;
                margin: 10px 0 10px 0;
                line-height: 35px;

                .avatar {
                    margin-right: 10px;
                }

                .username {
                    font-size: 12px;
                    font-weight: 700;
                    color: #40456b !important;
                }
            }

            .operator:hover {
                background: #fbfbfb;
            }
        }
    }

    section.chat-container-empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(98, 70, 234, 0.05);
        box-shadow: 2px 1px 10px rgba(#a9a6a6, 0.4);
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    section.chat-container {
        display: flex;
        flex-direction: column;
        background: #fff;
        box-shadow: 2px 1px 10px rgba(#a9a6a6, 0.4);
        width: 100%;
        height: 100%;
        margin: 0 auto;

        .chat-container-header {
            border-bottom: 1px solid #ebecff;
        }

        .chat-log-wrapper {
            position: relative;
            flex: 1 1 auto;
            overflow: hidden;
            margin: 1px 0 1px 0;

            .imageC {
                z-index: 777;
                position: absolute;
                background: #ffffff;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 16px;

                img {
                    border-radius: 8px;
                    width: 100%;
                    height: 100%;
                }

                .btnClose {
                    position: absolute;
                    top: 36px;
                    right: 32px;
                    background: rgba(0, 0, 0, 0.6);
                    border-radius: 4px;
                    border: 1px solid rgba(255, 255, 255, 0.75);
                    padding: 0 8px;
                    margin: 0 2px;
                    max-height: 36px;
                    height: 36px;
                    line-height: 36px;
                    color: #fff;
                }
            }

            .shortcutsC {
                z-index: 777;
                position: absolute;
                background: #ffffff;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 16px;

                .ant-tabs-tab-btn {
                    font-size: 14px !important;
                    line-height: 16px !important;
                }

                .shortcut {
                    background: #fdfcff;
                    border: 1px solid #ebecff;
                    box-sizing: border-box;
                    border-radius: 100px;
                    padding: 6px 12px;
                    font-size: 13px;
                }

                .shortcut:hover {
                    background: rgba(104, 0, 208, 0.05);
                    cursor: pointer;
                }
            }

            .chat-log {
                position: relative;
                display: flex;
                height: 100%;
                flex-direction: column;
                overflow-y: auto;
                box-sizing: border-box;
                padding: 16px;

                .chat-date-container {
                    margin: 20px 0 20px 0;
                    text-align: center;

                    .chat-date {
                        background: #ebecff;
                        border-radius: 5px;
                    }
                }

                .chat-message-info {
                    margin: 20px 0 20px 0;
                    font-size: 14px;
                    text-align: center;
                    color: #767990;

                    .chat-message {
                        margin: 4px 0 0 0;
                    }

                    .chat-message-datetime {
                        margin: 4px 0 0 0;
                        font-size: 10px;
                        line-height: 1;
                        letter-spacing: 0.4px;
                        color: #767990;
                    }
                }

                .chat-message {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin: 0 0 16px 0;
                    transition: all 0.5s;

                    .row1 {
                        display: flex;
                        flex-direction: row;

                        .chat-message-by {
                            margin: 0 0 4px 40px;
                            font-size: 11px;
                            line-height: 14px;
                            letter-spacing: 0.4px;
                            color: #767990;
                        }
                    }

                    .row2 {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .ant-badge-count,
                        .ant-badge-dot,
                        .ant-badge .ant-scroll-number-custom-component {
                            top: 31px;
                            right: 15px;
                        }

                        .chat-message-avatar {
                            margin: 0 8px 0 0;
                        }

                        .chat-message-text {
                            user-select: text;
                            -moz-user-select: text;
                            -webkit-user-select: text;
                            -ms-user-select: text;
                            
                            display: block;
                            max-width: 65%;
                            margin: 0 0 0 0;
                            background: #f9faff;
                            border: 1px solid #ebecff;
                            box-sizing: border-box;
                            border-radius: 5px !important;
                            padding: 4px 12px;
                            text-align: left;
                            font-weight: 300;
                            color: #40456b;

                            animation-duration: 0.3s;
                            animation-name: fly-in;

                            .typing {
                                float: right;
                                font-size: 10px;
                                color: #dddddd;
                            }

                            p {
                                margin: 0 0 0 0;
                            }

                            a {
                                text-decoration: underline;
                                color: inherit !important;
                            }

                            .file-wrapper {
                                position: relative;

                                .file {
                                    display: inline-block;
                                    border-radius: 8px;
                                    margin: 4px 0 4px 0;
                                }

                                .imgFile {
                                    border-radius: 8px;
                                    max-width: 180px;
                                    max-height: 180px;
                                    margin: 4px 0 4px 0;
                                }

                                .imgFile:hover {
                                    cursor: pointer;
                                }

                                .file-actions {
                                    position: absolute;
                                    bottom: 12px;
                                    right: 8px;
                                }

                                .action {
                                    background: rgba(0, 0, 0, 0.6);
                                    border-radius: 4px;
                                    border: 1px solid rgba(255, 255, 255, 0.75);
                                    padding: 0 8px;
                                    margin: 0 2px;
                                    max-height: 24px;
                                    height: 24px;
                                    line-height: 24px;
                                    color: #fff;
                                }

                                .action:hover {
                                    transform: scale(1.05);
                                }
                            }
                        }

                        .container-edit-message-form {
                            position: relative;
                            background: #fdfcff;
                            border: 1px solid #ebecff;
                            width: 80%;
                
                            .ant-form,
                            .ant-form-item {
                                margin: 0 0 0 0 !important;
                            }
                
                            .edit-message-form {
                                display: flex;
                                position: relative;
                                height: auto;
                                z-index: 2;
                                margin: 0 0 0 0;
                
                                .edit-message-form-input-C {
                                    width: 100%;
                
                                    .edit-message-form-input {
                                        background: #ffffff;
                                        border-radius: 24px;
                                        border: 1px solid #ebecff;
                                        box-sizing: border-box;
                                        padding: 0 16px 0 16px;
                                        min-height: 32px;
                
                                        &:focus {
                                            outline: none;
                                        }
                
                                        textarea {
                                            resize: none !important;
                                            // white-space: nowrap !important;
                                            min-height: 24px !important;
                                            // height: 50px !important;
                                            line-height: 24px !important;
                                            margin: auto 0 auto 0;
                                            padding: 0 0 0 0;
                                            outline: none !important;
                                            border: 0 none !important;
                                            -webkit-box-shadow: none;
                                            -moz-box-shadow: none;
                                            box-shadow: none;
                                        }
                                    }
                
                                    .edit-message-form-input.small {
                                        textarea {
                                            font-size: 13px;
                                        }
                                    }
                
                                    .edit-message-form-input.medium {
                                        textarea {
                                            font-size: 14px;
                                        }
                                    }
                
                                    .edit-message-form-input.large {
                                        textarea {
                                            font-size: 15px;
                                        }
                                    }
                                }
                
                                .edit-message-form-buttons {
                                    flex: 0 0 36px;

                                    .edit-message-form-shortcuts-button {
                                        outline: none !important;
                                        background-color: transparent;
                                        background-position: center;
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        border: none;
                                        margin: 0 6px 0 6px;
                                        padding: 0 0 0 0;
                                        font-size: 18px;
                                        color: #767990;
                
                                        &:focus {
                                            outline: none;
                                        }
                
                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }
                
                                    .edit-message-form-upload-button {
                                        button {
                                            outline: none !important;
                                            background-color: transparent;
                                            background-position: center;
                                            background-size: contain;
                                            background-repeat: no-repeat;
                                            border: none;
                                            margin: 0 6px 0 6px;
                                            padding: 0 0 0 0;
                                            font-size: 18px;
                                            color: #767990;
                
                                            &:focus {
                                                outline: none;
                                            }
                
                                            &:hover {
                                                cursor: pointer;
                                            }
                                        }
                
                                        .ant-upload-list-text {
                                            display: none;
                                        }
                                    }
                
                                    .edit-message-form-submit-button {
                                        outline: none !important;
                                        background-color: transparent;
                                        background-position: center;
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        border: none;
                                        margin: 0 6px 0 6px;
                                        padding: 0 0 0 0;
                                        font-size: 18px;
                                        color: #767990;
                
                                        &:focus {
                                            outline: none;
                                        }
                
                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                
                            .edit-message-form-emoji-picker-container {
                                flex: 0 0 36px;
                                height: 32px;
                                line-height: 50px;
                                z-index: 999;
                                border: none;
                                margin: auto;
                
                                .icon {
                                    display: inline-block;
                                    margin: 0 4px 0 8px;
                                    font-size: 20px;
                                    color: #767990 !important;
                                }
                
                                .icon:hover {
                                    cursor: pointer;
                                }
                
                                .picker {
                                    z-index: 888;
                                    position: absolute;
                                    bottom: 68px;
                                    left: 0px;
                                }
                            }
                        }

                        .chat-message-text.error {
                            background: #ceaff0;
                            border: 1px solid #ceaff0;
                        }

                        .message-action {
                            font-size: 11px;
                            color: #767990;
                        }

                        .message-action:hover {
                            transform: scale(1.20);
                            cursor: pointer;
                        }
                    }

                    .row3 {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .chat-message-datetime {
                            margin: 4px 0 0 40px;
                            font-size: 10px;
                            line-height: 1;
                            letter-spacing: 0.4px;
                            color: #767990;
                        }
                    }

                    // Other user variation
                    &--right {
                        flex-direction: col;

                        .row1 {
                            flex-direction: row-reverse;

                            .chat-message-by {
                                margin: 0 40px 4px 0;
                            }
                        }

                        .row2 {
                            flex-direction: row-reverse;

                            .ant-badge-count,
                            .ant-badge-dot,
                            .ant-badge .ant-scroll-number-custom-component {
                                top: 31px;
                                right: 6px;
                            }

                            .chat-message-avatar {
                                margin: 0 0 0 8px;
                            }

                            .chat-message-text {
                                background: #6800d0;
                                border: 1px solid #6800d0;
                                text-align: left;
                                color: #ffffff;
                            }

                            .chat-message-text.error {
                                background: #ceaff0;
                                border: 1px solid #ceaff0;
                            }
                        }

                        .row3 {
                            flex-direction: row-reverse;

                            .chat-message-datetime {
                                margin: 4px 40px 0 0;
                            }

                            .ant-progress {
                                width: 180px;
                                margin: 0 24px;
                            }
                        }
                    }
                }

                .chat-message.small { font-size: 13px; }
                .chat-message.medium { font-size: 14px; }
                .chat-message.large { font-size: 15px; }

                // Add more space when users alternate
                .chat-message:not(.chat-message--right) + .chat-message--right,
                .chat-message--right + .chat-message:not(.chat-message--right) {
                    margin-top: 16px;
                }

                // Add a corner on first message for user
                .chat-message:not(.chat-message--right) + .chat-message--right {
                    .chat-message-text {
                        border-radius: 18px 0 18px 18px;
                    }
                }

                .chat-message:first-of-type,
                .chat-message--right + .chat-message:not(.chat-message--right) {
                    .chat-message-text {
                        border-radius: 0 18px 18px;
                    }
                }
            }
        }

        .container-chat-form {
            position: relative;
            background: #fdfcff;
            border: 1px solid #ebecff;

            .ant-form,
            .ant-form-item {
                margin: 0 0 0 0 !important;
            }

            .chat-form {
                display: flex;
                position: relative;
                // height: 50px;
                z-index: 2;
                margin: 0 0 0 0;

                .chat-form-input-C {
                    width: 100%;

                    .chat-form-input {
                        background: #ffffff;
                        border-radius: 24px;
                        border: 1px solid #ebecff;
                        box-sizing: border-box;
                        padding: 8px 16px 8px 16px;
                        min-height: 50px;

                        &:focus {
                            outline: none;
                        }

                        textarea {
                            resize: none !important;
                            // white-space: nowrap !important;
                            min-height: 24px !important;
                            // height: 50px !important;
                            line-height: 24px !important;
                            margin: auto 0 auto 0;
                            padding: 0 0 0 0;
                            outline: none !important;
                            border: 0 none !important;
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            box-shadow: none;
                        }
                    }

                    .chat-form-input.small {
                        textarea {
                            font-size: 13px;
                        }
                    }

                    .chat-form-input.medium {
                        textarea {
                            font-size: 14px;
                        }
                    }

                    .chat-form-input.large {
                        textarea {
                            font-size: 15px;
                        }
                    }
                }

                .chat-form-buttons {
                    flex: 0 0 60px;
                    height: 50px;
                    line-height: 50px;

                    .chat-form-shortcuts-button {
                        outline: none !important;
                        background: #f9faff !important;
                        border: 1px solid #ebecff !important;
                        border-radius: 100px;
                        height: 32px;
                        line-height: 32px;
                        margin: 0 6px 0 6px;
                        padding: 0 10px;
                        font-weight: 500;
                        font-size: 14px;
                        color: #767990;

                        &:focus {
                            outline: none;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .chat-form-upload-button {
                        button {
                            outline: none !important;
                            background-color: transparent;
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            border: none;
                            margin: 0 6px 0 6px;
                            padding: 0 0 0 0;
                            font-size: 18px;
                            color: #767990;

                            &:focus {
                                outline: none;
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .ant-upload-list-text {
                            display: none;
                        }
                    }

                    .chat-form-submit-button {
                        outline: none !important;
                        background-color: transparent;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        border: none;
                        margin: 0 6px 0 6px;
                        padding: 0 0 0 0;
                        font-size: 18px;
                        color: #767990;

                        &:focus {
                            outline: none;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .chat-form-font-size-selector-container {
                flex: 0 0 36px;
                height: 50px;
                line-height: 50px;
                z-index: 999;
                border: none;
                margin: auto;

                .icon {
                    display: inline-block;
                    margin: 0 4px 0 8px;
                    font-size: 20px;
                    color: #767990 !important;
                }

                .icon:hover {
                    cursor: pointer;
                }
            }

            .chat-form-emoji-picker-container {
                flex: 0 0 36px; 
                height: 50px;
                line-height: 50px;
                z-index: 999;
                border: none;
                margin: auto;

                .icon {
                    display: inline-block;
                    margin: 0 4px 0 8px;
                    font-size: 20px;
                    color: #767990 !important;
                }

                .icon:hover {
                    cursor: pointer;
                }

                .picker {
                    z-index: 888;
                    position: absolute;
                    bottom: 68px;
                    left: 0px;
                }
            }
        }

        .chat-form-emoji-picker-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
        }
    }

    .user-details {
        font-size: 12px;
        line-height: 16px;

        .operator {
            cursor: pointer;
            border-radius: 4px;
            margin: 10px 0 0 0;
            line-height: 35px;

            .ant-badge-count,
            .ant-badge-dot,
            .ant-badge .ant-scroll-number-custom-component {
                top: 31px;
                right: 15px;
            }

            .avatar {
                margin-right: 10px;
            }

            .username {
                font-size: 12px;
                font-weight: 700;
                color: #40456b !important;
            }
        }
    }
}

.imgModal {
    padding: 0 0 0 0 !important;

    .ant-modal-content .ant-modal-body {
        padding: 0 0 0 0 !important;
    }
}

@primary-color: #6800D0;@error-color: #DC143C;@text-color: #40456B;