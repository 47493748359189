.flexRow {
    display: flex;
    width: 100%;
}

    .flexRow .colContentPaneLeft {
        border-radius: 5px;
        width: 180px;
        flex: 0 0 180px;
        -ms-flex: 0 0 180px;
    }

    .flexRow .colContentPaneRight {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: hidden;
        max-width: 100%;
        flex-grow: 2;
    }

.welcomeC {
    position: relative;
}

    .welcomeC .logo {
        position: absolute;
        top: 12px;
        left: 12px;
        right: 12px;
        width: 140px;
        margin: 0 auto;
    }

    .welcomeC .helper {
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        text-align: center;
        font-weight: 500x;
        font-size: 14px;
        color: #FFFFFF;
    }

.loginC {
    background: #F9FAFF;
    box-shadow: 0px 10px 20px 10px #EBECFF;
    border-radius: 10px;
}   

    .loginFormWrapper {
        padding: 36px;
    }

        .loginForm {
            max-width: 360px;
            min-width: 300px;
            margin: auto;
        }