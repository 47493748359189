@keyframes fly-in {
    0% {
        transform: scale(0.85) translateY(10%);
        opacity: 0;
    }
    100% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}

.page-ChatHistory {
    section {
        .section-title {
            border-bottom: 1px solid #ebecff;
        }
    }

    section.chat-list {
        display: block;
        background: #ffffff;

        .chat-list-item.active {
            background: rgba(65, 79, 247, 0.05);
            border: 1px solid #6800d0;
        }

        .chat-list-item {
            background: #fdfcff;
            border: 1px solid #ebecff;
            box-sizing: border-box;
            border-radius: 5px;
            margin: 8px 0 0 0;
            padding: 12px 10px 12px 10px;

            .avatar {
                float: left;
                margin-right: 10px;
            }

            .container {
                .row1 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;

                    .username {
                        flex-grow: 1;
                        flex-shrink: 1;
                        flex-basis: auto;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 14px;
                        color: #40456b !important;
                    }

                    .time {
                        font-size: 10px;
                        line-height: 14px;
                        color: #666;
                    }
                }

                .row2 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;

                    .message {
                        flex-grow: 1;
                        flex-shrink: 1;
                        font-size: 11px;
                        font-size: 12px;
                        line-height: 14px;
                        color: #767990 !important;
                    }

                    .badge-container {
                        min-width: 40px;
                        text-align: right;
                    }
                }
            }
        }
    }

    section.chat-container-empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(98, 70, 234, 0.05);
        box-shadow: 2px 1px 10px rgba(#a9a6a6, 0.4);
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    section.chat-container {
        display: flex;
        flex-direction: column;
        background: #fff;
        box-shadow: 2px 1px 10px rgba(#a9a6a6, 0.4);
        width: 100%;
        height: 100%;
        margin: 0 auto;

        .chat-container-header {
            border-bottom: 1px solid #ebecff;
        }

        .chat-log-wrapper {
            position: relative;
            flex: 1 1 auto;
            overflow: hidden;
            height: calc(100% - 135px);
            margin: 1px 0 1px 0;

            .imageC {
                z-index: 777;
                position: absolute;
                background: #ffffff;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 16px;

                img {
                    border-radius: 8px;
                    width: 100%;
                    height: 100%;
                }

                .btnClose {
                    position: absolute;
                    top: 36px;
                    right: 32px;
                    background: rgba(0, 0, 0, 0.6);
                    border-radius: 4px;
                    border: 1px solid rgba(255, 255, 255, 0.75);
                    padding: 0 8px;
                    margin: 0 2px;
                    max-height: 36px;
                    height: 36px;
                    line-height: 36px;
                    color: #fff;
                }
            }

            .shortcutsC {
                overflow: auto;
                z-index: 777;
                position: absolute;
                background: #ffffff;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 16px;

                .ant-tabs-tab-btn {
                    font-size: 14px !important;
                    line-height: 16px !important;
                }

                .shortcut {
                    background: #fdfcff;
                    border: 1px solid #ebecff;
                    box-sizing: border-box;
                    border-radius: 24px;
                    padding: 6px 12px;
                    font-size: 13px;
                }

                .shortcut:hover {
                    background: rgba(104, 0, 208, 0.05);
                    cursor: pointer;
                }
            }

            .chat-log-container {
                overflow: auto;
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                padding: 16px 16px 16px 16px;

                .chat-log-message-wrapper {
                    clear: both;
                    width: 100%;
                    margin: 0 0 0 0;
                    padding: 0px 0px 12px 0;

                    &:before, &:after {
                        clear: both;
                        content: " ";
                        display: table;
                    }

                    &.font-size--10 { font-size: 12px; }
                    &.font-size--20 { font-size: 14px; }
                    &.font-size--30 { font-size: 16px; }
                    
                    .chat-log-message-container {
                        clear: both;
                        position: relative;
                        width: calc(100% - 48px);
                        text-align: left;

                        .message-text {
                            padding: 16px 24px;
                            border-radius: 5px;
                            line-height: 1.4;
                        }

                        .message-file {
                            max-width: 320px;
                            padding: 4px 4px 4px 4px;
                            border-radius: 5px;
                            line-height: 1.4;

                            .message-file--image {
                                width: 100%;
                                border-radius: 5px;
                            }

                            .message-file---actions {
                                position: absolute;
                                bottom: 22px;
                                right: 32px;

                                button.action {
                                    background: rgba(0, 0, 0, 0.5);
                                    border: 0 none;
                                    border-radius: 5px;
                                    color: #FFF;
                                }
                            }
                        }

                        .message-carousel {
                            max-width: 320px;
                            padding: 4px 4px 4px 4px;
                            border-radius: 5px;
                            line-height: 1.4;

                            .ant-carousel .slick-prev,
                            .ant-carousel .slick-next,
                            .ant-carousel .slick-prev:hover,
                            .ant-carousel .slick-next:hover {
                              font-size: inherit;
                              color: currentColor;
                            }
                            
                            .carousel-item {
                                .carousel-item--image {
                                    width: 100%;
                                    border-radius: 5px;
                                }

                                .carousel-item--title {
                                    border-bottom: 1px solid #DDD;
                                    margin: 4px 0 0 0;
                                    padding: 4px 6px 4px 6px;
                                    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                                    line-height: 1.25;
                                    color: #000;
                                }

                                .carousel-item--description {
                                    margin: 4px 0 0 0;
                                    padding: 4px 6px 4px 6px;
                                    line-height: 1.25;
                                    color: rgb(115, 115, 118);
                                }

                                .carousel-item--button-group {
                                    border-top: 1px solid #DDD;
                                    margin: 4px 0 0 0;
                                    padding: 10px 6px 10px 6px;

                                    .btn-action {
                                        display: block;
                                        width: calc(100% - 8px);
                                        background: #EBECFF;
                                        border: 0 none;
                                        height: auto;
                                        margin: 0 4px 8px 4px;
                                        padding: 8px 16px;
                                        line-height: 1;
                                        color: #40456B;
                                        cursor: pointer;

                                        &:hover {
                                            background: darken(#EBECFF, 5%);
                                        }

                                        &.type-20 {
                                            background: #D4F1F4;
                                            color: #05445E;

                                            &:hover {
                                                background: darken(#D4F1F4, 5%);
                                            }
                                        }

                                        &.type-30 {
                                            background: #F6EFFD;
                                            color: #6800D0;

                                            &:hover {
                                                background: darken(#F6EFFD, 5%);
                                            }
                                        }

                                        &.type-90 {
                                            background: rgba(255, 89, 86, 0.05);
                                            color: #FF5956;

                                            &:hover {
                                                background: rgba(255, 89, 86, 0.15);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .message-button-group {
                            .btn-action {
                                display: inline-block;
                                background: #EBECFF;
                                border: 0 none;
                                border-radius: 100px;
                                height: auto;
                                margin: 0 4px 8px 4px;
                                padding: 16px 24px;
                                line-height: 1;
                                color: #40456B;
                                cursor: pointer;

                                &:hover {
                                    background: darken(#EBECFF, 5%);
                                }

                                &.type-20 {
                                    background: #D4F1F4;
                                    color: #05445E;

                                    &:hover {
                                        background: darken(#D4F1F4, 5%);
                                    }
                                }

                                &.type-30 {
                                    background: #F6EFFD;
                                    color: #6800D0;

                                    &:hover {
                                        background: darken(#F6EFFD, 5%);
                                    }
                                }

                                &.type-90 {
                                    background: rgba(255, 89, 86, 0.05);
                                    color: #FF5956;

                                    &:hover {
                                        background: rgba(255, 89, 86, 0.15);
                                    }
                                }
                            }
                        }
                    }

                    &.chat-log-message-wrapper--center {
                        float: none;
                        text-align: center;

                        .chat-log-message-container {
                            margin: 0 auto 0 auto;
                        }
                    }

                    &.chat-log-message-wrapper--left {
                        float: left;
                        width: 80%;

                        .chat-log-message-container {
                            float: left;
                            position: relative;
                            padding-left: 45px;

                            .message-by--avatar {
                                position: absolute;
                                left: 0;
                                bottom: 28px;
                            }
                            
                            .message-text {
                                float: left;
                                background-color: rgb(245, 245, 245);
                                color: #000000;

                                &.message-by-operator {
                                    background-color: #faf5ff;
                                }
                            }

                            .message-file {
                                position: relative;
                                float: left;
                                background-color: rgb(245, 245, 245);
                                color: #000000;

                                &.message-by-operator {
                                    background-color: #faf5ff;
                                }

                                .message-file---actions {
                                    bottom: 16px;
                                    right: 16px;
                                }
                            }

                            .message-carousel {
                                background-color: rgb(245, 245, 245);
                                color: #000000;

                                &.message-by-operator {
                                    background-color: #faf5ff;
                                }
                            }

                            .message-details {
                                clear: both;
                                padding: 4px 0 0 0;
                                font-size: 12px;
                                color: rgb(115, 115, 118);
                            }
                        }
                    }

                    &.chat-log-message-wrapper--right {
                        float: right;
                        width: 80%;
                        margin-left: auto;

                        .chat-log-message-container {
                            float: right;
                            
                            .message-text {
                                float: right;
                                background-color: #F6EFFD;
                                color: #000000;

                                &.message-text-error {
                                    background-color: #fcf8ff;
                                    border: 1px solid #fcf8ff;
                                    color: #666;
                                }
                            }

                            .message-file {
                                float: right;
                                background-color: #F6EFFD;
                                color: #000000;

                                .message-file---actions {
                                    bottom: 32px;
                                    right: 16px;
                                }
                            }

                            .message-details {
                                float: right;
                                clear: both;
                                padding: 4px 0 0 0;
                                font-size: 12px;
                                color: rgb(115, 115, 118);
                            }
                        }
                    }

                    &:last-child .chat-log-message-container .message-text {
                        margin: 0 0 0 0;
                    }
                }

                .chat-log-container--bottom {
                    height: 1px;
                }
            }
        }

        .container-chat-form {
            position: relative;
            background: #fdfcff;
            border: 1px solid #ebecff;

            .ant-form,
            .ant-form-item {
                margin: 0 0 0 0;
            }

            .chat-form {
                position: relative;
                height: 50px;
                z-index: 2;
                margin: 0 0 0 0;

                .chat-form-input {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #ffffff;
                    border-radius: 100px;
                    border: 1px solid #ebecff;
                    box-sizing: border-box;
                    padding: 0 172px 0 42px;
                    min-height: 50px;

                    &:focus {
                        outline: none;
                    }

                    .rc-textarea {
                        white-space: nowrap !important;
                        min-height: 20px !important;
                        height: 20px !important;
                        line-height: 20px !important;
                        margin: auto 0 auto 0;
                        padding: 0 0 0 0;
                    }
                }

                .chat-form-buttons {
                    position: absolute;
                    top: 0;
                    right: 8px;
                    height: 50px;
                    line-height: 50px;

                    .chat-form-shortcuts-button {
                        outline: none !important;
                        background: #f9faff !important;
                        border: 1px solid #ebecff !important;
                        border-radius: 100px;
                        height: 32px;
                        line-height: 32px;
                        margin: 0 6px 0 6px;
                        padding: 0 10px;
                        font-weight: 500;
                        font-size: 14px;
                        color: #767990;

                        &:focus {
                            outline: none;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .chat-form-upload-button {
                        button {
                            outline: none !important;
                            background-color: transparent;
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            border: none;
                            margin: 0 6px 0 6px;
                            padding: 0 0 0 0;
                            font-size: 18px;
                            color: #767990;

                            &:focus {
                                outline: none;
                            }

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .ant-upload-list-text {
                            display: none;
                        }
                    }

                    .chat-form-submit-button {
                        outline: none !important;
                        background-color: transparent;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        border: none;
                        margin: 0 6px 0 6px;
                        padding: 0 0 0 0;
                        font-size: 18px;
                        color: #767990;

                        &:focus {
                            outline: none;
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .chat-form-emoji-picker-container {
                position: absolute;
                top: 0;
                left: 8px;
                height: 50px;
                line-height: 50px;
                z-index: 999;
                border: none;
                margin: auto;

                .icon {
                    display: inline-block;
                    margin: 0 4px 0 8px;
                    font-size: 20px;
                    color: #767990 !important;
                }

                .icon:hover {
                    cursor: pointer;
                }

                .picker {
                    z-index: 888;
                    position: absolute;
                    bottom: 68px;
                    left: 0px;
                }
            }
        }

        .chat-form-emoji-picker-overlay {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
        }
    }

    .user-details {
        font-size: 12px;
        line-height: 16px;
    }
}

.imgModal {
    padding: 0 0 0 0 !important;

    .ant-modal-content .ant-modal-body {
        padding: 0 0 0 0 !important;
    }
}

@primary-color: #6800D0;@error-color: #DC143C;@text-color: #40456B;