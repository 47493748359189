.assignTaskF .ant-row.ant-form-item {
    margin: 0 0 4px 0 !important;
}

.changeStatusSelector .ant-select-selector {
    border-radius: 100px !important;
}

.changeStatusSelector.Pending .ant-select-selector {
    background: #fff0f6 !important;
    border-color: #ffadd2 !important;
    color: #eb2f96 !important;
}

.changeStatusSelector.Assigned .ant-select-selector {
    background: #fffbe6 !important;
    border-color: #ffe58f !important;
    color: #faad14 !important;
}

.changeStatusSelector.Resolved .ant-select-selector {
    background: #f6ffed !important;
    border-color: #b7eb8f !important;
    color: #52c41a !important;
}