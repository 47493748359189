.ticketsT table {
    background: rgb(249, 250, 255) !important;
    border-collapse: separate !important; 
    border-spacing: 0 16px !important;
}

#ticketsTHead table {
    border-spacing: 0 0 !important;
    margin: 16px 0 0 0;
}

    .ticketsT table thead th {
        background: #40456B !important;
        padding: 8px 12px 8px 12px !important;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #FFFFFF !important;
    }

    .ticketsT table thead th:first-child {
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
    }

    .ticketsT table thead th:last-child {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
    }

    #ticketsTHead table tbody {
        display: none;
    }

    .ticketsT table tbody tr {
        background: #FFFFFF !important;
        box-sizing: border-box;
    }

    .ticketsT table tbody tr:hover {
        cursor: pointer;
    }

        #ticketsTHead .ant-table-selection {
            display: none;
        }

        .ticketsT table tbody td {
            border-top: 1px solid rgba(104, 0, 208, 0.1) !important;
            border-bottom: 1px solid rgba(104, 0, 208, 0.1) !important;
            padding: 20px 16px 20px 16px !important;
            font-weight: 400;
            font-size: 12px;
            line-height: 1.5;
            color: #000000;
        }

        .ticketsT table tbody tr.ant-table-row-selected > td {
            background: inherit !important;
            border-top: 1px solid rgba(104, 0, 208, 1) !important;
            border-bottom: 1px solid rgba(104, 0, 208, 1) !important;
        }

        .ticketsT table tbody td.actionsC {
            background: #F9FAFF;
            padding: 20px 12px 20px 12px !important;
        }

        .ticketsT table tbody td:first-child {
            border-left: 1px solid rgba(104, 0, 208, 0.1) !important;
            border-top-left-radius: 5px !important;
            border-bottom-left-radius: 5px !important;
        }

        .ticketsT table tbody tr.ant-table-row-selected > td:first-child {
            border-left: 1px solid rgba(104, 0, 208, 1) !important;
        }

        .ticketsT table tbody td:last-child {
            border-right: 1px solid rgba(104, 0, 208, 0.1) !important;
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
        }

        .ticketsT table tbody tr.ant-table-row-selected > td:last-child {
            border-right: 1px solid rgba(104, 0, 208, 1) !important;
        }

            .ticketsT table tbody tr td .ant-checkbox-checked .ant-checkbox-inner {
                background: #FFFFFF;
            }

            .ticketsT table tbody tr td .ant-checkbox-checked .ant-checkbox-inner::after {
                border-color: rgba(104, 0, 208, 1) !important;
            }

            .ant-checkbox-inner::after {
                top: -5px !important;
            }

.assignTasksFormC {
    background: #FFF;
    border: 1px solid #EBECFF;
    border-radius: 5px;
    margin: 16px auto 0 auto;
    padding: 16px 16px 16px 16px;
    text-align: center;
}

    .assignTasksFormC form {
        display: inline-block;
    }

    .assignTasksFormC .ant-form-item,
    .assignTasksFormC .ant-form-item-control {
        display: inline-block;
        margin: 0 0 0 0 !important;
    }