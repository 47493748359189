.card {
    background: #FFFFFF;
    border: 1px solid #EBECFF;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px 10px #EBECFF;
    border-radius: 10px;
    margin: 0 0 12px 0;
    padding: 24px 24px 24px 24px;
    text-align: center;
}

.card:hover {
    transform: scale(1.02);
}

    .operators {
        border-bottom: 2px solid #56CCF2;
        color: #56CCF2;
    }

        .operators .title {
            margin: 6px 0 6px 0;
            font-weight: 700;
            font-size: 22px;
            color: #56CCF2;
        }

        .operators .description {
            margin: 6px 0 6px 0;
            font-weight: 400;
            font-size: 14px;
            color: #767990;
        }

    .departments {
        border-bottom: 2px solid #6800D0;
        color: #6800D0;
    }

        .departments .title {
            margin: 6px 0 6px 0;
            font-weight: 700;
            font-size: 22px;
            color: #6800D0;
        }

        .departments .description {
            margin: 6px 0 6px 0;
            font-weight: 400;
            font-size: 14px;
            color: #767990;
        }

    .shortcuts {
        border-bottom: 2px solid #F2994A;
        color: #F2994A;
    }

        .shortcuts .title {
            margin: 6px 0 6px 0;
            font-weight: 700;
            font-size: 22px;
            color: #F2994A;
        }

        .shortcuts .description {
            margin: 6px 0 6px 0;
            font-weight: 400;
            font-size: 14px;
            color: #767990;
        }

    .settings {
        border-bottom: 2px solid #FFC300;
        color: #FFC300;
    }

        .settings .title {
            margin: 6px 0 6px 0;
            font-weight: 700;
            font-size: 22px;
            color: #FFC300;
        }

        .settings .description {
            margin: 6px 0 6px 0;
            font-weight: 400;
            font-size: 14px;
            color: #767990;
        }

    .widget {
        border-bottom: 2px solid #00FF00;
        color: #00FF00;
    }

        .widget .title {
            margin: 6px 0 6px 0;
            font-weight: 700;
            font-size: 22px;
            color: #00FF00;
        }

        .widget .description {
            margin: 6px 0 6px 0;
            font-weight: 400;
            font-size: 14px;
            color: #767990;
        }