.statCard {
    background: #FFFFFF;
    border: 1px solid #EBECFF;
    border-radius: 10px;
    padding: 12px 16px;
}

.cardPurple {
    background: #ECEFFF !important;
}

    .statLabel {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #6800D0;
    }

    .statIconC {
        font-size: 36px;
        line-height: 50px;
        color: #6800D0;
    }

    .statVal {
        font-weight: 700;
        font-size: 28px;
        line-height: 50px;
        color: #40456B;
    }

.statusOnlineIndicator {
    display: inline-block;
    background: #31D696;
    border-radius: 100%;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    width: 8px;
    height: 8px;
    margin: 0 8px 0 8px;
}

.statusAwayIndicator {
    display: inline-block;
    background: #FFD967;
    border-radius: 100%;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    width: 8px;
    height: 8px;
    margin: 0 8px 0 8px;
}

.statusOfflineIndicator {
    display: inline-block;
    background: #FF5956;
    border-radius: 100%;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    width: 8px;
    height: 8px;
    margin: 0 8px 0 8px;
}