.logoContainer {
    height: 64px;
    margin: 0 8px 0 8px;
}

    .logo {
        position: relative;
        display: inline-block;
        height: 28px;
        margin: 18px 8px 18px 8px;
        font-weight: 800;
        font-size: 20px;
    }

        .logo a {
            color: #6246EA;
        }