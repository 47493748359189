.flexRow {
    display: flex;
    width: 100%;
}

    .flexRow .colContentPaneLeft {
        width: 40px;
        flex: 0 0 40px;
        -ms-flex: 0 0 40px;
    }

    .flexRow .colContentPaneRight {
        overflow-y: hidden;
        max-width: 100%;
        flex-grow: 2;
    }

        .notificationC {
            border-bottom: 1px solid #EBECFF;
            padding: 6px 8px;
        }

        .notificationC:hover {
            background: #EBECFF;
            cursor: pointer;
        }

            .notificationC .notificationBody {
            }

                .notificationC .notifiedBy {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 15px;
                }

                    .notificationC .notificationBody .notifiedBy .seenIndicator {
                        margin: 0 0 0 4px;
                        font-size: 18px;
                        color: #6246EA;
                    }

                .notificationC .message {
                    width: 100%;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                }

                .notificationC .dateAndTime {
                    font-size: 10px;
                    line-height: 15px;
                }

        .seeAllBtn {
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            color: #40456B;
        }