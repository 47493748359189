.empty-W {
    background: #FFFFFF;
    border: 2px dashed #EBECFF;
    border-radius: 10px;
    padding: 40px 40px 40px 40px;
}

.chatbot-C {
    background: #FFFFFF;
    border: 1px solid #EBECFF;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px 10px #EBECFF;
    border-radius: 10px;
    text-align: center;

    img {
        width: 64px;
    }

    .name {
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        color: #40456B;
    }

    .helper {
        font-size: 16px;
        line-height: 24px;
        color: #767990;
    }

    .actions-C {
        .chatbot_btnPublish {
            background: #6800D0;;
            border: 1px solid #6800D0;
            border-radius: 50px;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF !important;
        }

        .chatbot_btnUnpublish {
            background: #FF5956;
            border: 1px solid #FF5956;
            border-radius: 50px;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF !important;
        }

        .chatbot_btnEdit {
            background: #F8F8F8;
            border: 1px solid #40456B;
            border-radius: 50px;
            padding: 7px 15px 7px 15px;
            font-weight: 500;
            font-size: 14px;
            color: #40456B !important;
        }
    }
    
    .status {
        display: inline-block;
        border-radius: 2px;
        padding: 2px 10px 2px 10px;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
    }

    .status.active {
        background: #31D696;
        color: #FFFFFF;
    }

    .status.inactive {
        background: #EBEBEB;
        color: rgba(0, 0, 0, 0.38);
    }
}

.chatbot-C.active {
    border: 2px solid #31D696;
    border-bottom: 8px solid #31D696;
}

.chatbot-C.inactive {
    border: 2px solid #40456B;;
    border-bottom: 8px solid #40456B;
}
@primary-color: #6800D0;@error-color: #DC143C;@text-color: #40456B;